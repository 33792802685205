<template>
    <div style="width: 100vw; height: 100vh; display:flex; justify-content: center; align-items: center">
        <div style="text-align: center">
            <div style="font-size: 30vh">
                404
            </div>
            
            <h2 style="opacity:.4">
                Oops. Nothing here...
            </h2>
            
            <ion-button
                class="q-mt-xl"
                color="dark"
                text-color="blue"
                href="/"
            >
                На главную
            </ion-button>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Error404',
});
</script>
